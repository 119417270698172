var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('a-card',[_c('template',{slot:"title"},[_c('strong',[_vm._v("Data Tabungan")]),_c('a-button-group',{staticClass:"ml-2"},[_c('a-button',{staticClass:"text-primary",attrs:{"icon":"plus-circle","type":""},on:{"click":function($event){return _vm.connector('anggota')}}},[_vm._v(" Tambah ")]),_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Refresh Data")])]),_c('a-button',{on:{"click":function($event){return _vm.getAllData(true)}}},[_c('a-icon',{staticClass:"text-success",attrs:{"type":"sync","spin":_vm.loading}})],1)],2),_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("PDF Download")])]),_c('a-button',{on:{"click":_vm.pdfMutasiTabungan}},[_c('a-icon',{staticClass:"text-warning",attrs:{"type":"file-pdf"}})],1)],2),_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("Setting Pengesahan")])]),_c('a-button',{on:{"click":_vm.showModalPengesahan}},[_c('a-icon',{staticClass:"text-secondary",attrs:{"type":"setting"}})],1)],2)],1)],1),_c('a-table',{staticStyle:{"padding-top":"-25px"},attrs:{"scroll":{ x: 1000 },"columns":_vm.columns,"dataSource":_vm.datatable,"pagination":{
            hideOnSinglePage: true,
            defaultPageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          },"size":"small"},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.dataIndex}`,"value":selectedKeys[0]},on:{"change":(e) => setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
                  .toString()
                  .split(
                    new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i')
                  )),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[_vm._v(" "+_vm._s(text)+" ")]]}},{key:"name",fn:function(text){return _c('a',{attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(text))])}},{key:"tags",fn:function(tags){return _c('span',{},_vm._l((tags),function(tag){return _c('a-tag',{key:tag,attrs:{"color":"blue"}},[_vm._v(_vm._s(tag))])}),1)}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a',{staticClass:"text-success",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.cetakValidasi(record)}}},[_c('a-icon',{attrs:{"type":"printer"}})],1),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{staticClass:"text-danger",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showDeleteConfirm(record)}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)}},{key:"kodetransaksi",fn:function(text, record){return _c('span',{},[_vm._v(" "+_vm._s(_vm.findRelationData( record, "masterkodeTransaksi", "kode", "kodetransaksi", "keterangan" ))+" ")])}},{key:"tgl",fn:function(text, record){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatDate(record.tgl))+" ")])}},{key:"created_at",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatDate(text, 'YYYY-MM-DDThh:mm:ss', 'DD-MM-YYYY hh:mm:ss'))+" ")])}}])},[_c('span',{attrs:{"slot":"customTitle"},slot:"customTitle"},[_c('a-icon',{attrs:{"type":"smile-o"}}),_vm._v(" Kode")],1)])],2)],1)]),_c('modalpengesahan',{ref:"modalPengesahan"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }